const getRedirectUri = (base) => {
	const { pathname, search, hash, origin } = window.location;
	const redirectPath = pathname + search + hash;
	
	if (!redirectPath.includes("?redirectPath")) {
		return origin + base + `/?redirectPath=${encodeURIComponent(redirectPath)}`;
	}

	return origin + base
};

export { getRedirectUri }