import axios from "axios";

export default {
	methods: {
		async getTenant(id) {
			try {
				const { data } = await axios.get(`/tenants/${id}`);

				return data;
			} catch (e) {
				this.$error("Cannot load tenant. Please try again");
			}
		},
		async saveTenant(id, data) {
			await axios.put(`/tenants/${id}/metadata`, data);
		},
		async createClientConfig(id) {
			try {
				const { data } = await axios.post(`/tenants/${id}/create-client-config`);

				this.$info("Configuration successfully created.")
				return data;
			} catch (e) {
				this.$error("Cannot create configuration. Please try again later.");
			}
		}
	},
}
