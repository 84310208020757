import axios from "axios";

import STORE_CONSTANTS from "./constants";

export default {
	namespaced: true,
	state: {
		mandants: [],
	},

	getters: {
		[STORE_CONSTANTS.mandants.getters.query]: state => state.mandants,
	},

	mutations: {
		[STORE_CONSTANTS.mandants.getters.query](state, payload) {
			state.mandants = payload;
		}
	},

	actions: {
		async [STORE_CONSTANTS.mandants.getters.query](context, partnerId) {
			const url = window.origin.match(".localhost.") ?
				`https://customers.test.eu-central-1.api.eu.abas.ninja/v1/partners/${partnerId}/customers` :
				`https://customers.prod.eu-central-1.api.eu.abas.cloud/v1/partners/${partnerId}/customers`;
			const { data } = await axios.get(url);

			context.commit(STORE_CONSTANTS.mandants.getters.query, data);
		}
	},
};
