<template>
	<img
		:src="getAvatar(imgUrl, username)"
		:alt="username"
		class="avatar"
		@error.once="setFallbackImg"
	>
</template>

<script>
export default {
	name: "UserAvatar",
	props: {
		disabled: {
			type: Boolean,
			default: false,
		},
		imgUrl: {
			type: String,
			default: "",
		},
		username: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			fallbackImgUrl: `${import.meta.env.BASE_URL}assets/generic-avatar.jpg`
		}
	},
	methods: {
		getAvatar(imgUrl, username) {
			if (imgUrl) {
				return imgUrl;
			}

			if (username) {
				const char = `${username[0]}${username[1]}`;
				return (
					// eslint-disable-next-line max-len
					`https://s.gravatar.com/avatar/7231b3789f5c794daf03a768dac46ff2?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2F${char}.png`
				);
			}
			return this.fallbackImgUrl;
		},
		setFallbackImg(e) {
			e.target.src = this.fallbackImgUrl;
		}
	}
}
</script>

<style lang="scss" scoped>
.avatar {
	height: 32px;
	width: 32px;
	border-radius: 100%;

	&[disabled] {
		-webkit-filter: opacity(20%); /* Safari 6.0 - 9.0 */
		filter: opacity(20%);
	}
}
</style>
