// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import { createApp } from "vue";
import "@abas/elements/style.css"
import { loadable } from "@abas/elements";
import VTooltip from "v-tooltip";
import VueObserveVisibility from "vue-observe-visibility";
import App from "./App.vue";
import ErrorApp from "./ErrorApp.vue";
import router from "./router";
import store from "./store";

/** PLUGINS */
import { AbasWebConfigurationVuePlugin, AbasWebAuthenticatorVuePlugin, AbasWebApplication } from '@abas/web-ui-authenticator';
import mitt from "mitt";

/** Utils Imports */
import { getRedirectUri } from './utils/getBaseUri';

/** Services Imports */
import { HttpClient } from './services/HttpClient';

/**
 * async functions
 *
 * *Return value*
 * A Promise which will be
 * - resolved with the value returned by the async function, or
 * - rejected with an exception thrown from, or uncaught within, the async function.
 */
const initializeApp = async () => {
	const baseUrl = import.meta.env.BASE_URL;

	try {
		const app = createApp(App)

		app.config.globalProperties.$filters = {
			date(dateString) {
				const localDate = new Date(dateString).toLocaleString();
				return localDate === "Invalid Date" ? "" : localDate;
			}
		}

		await AbasWebConfigurationVuePlugin('/configuration.json').then((plugin) => {
			app.use(plugin).use(AbasWebAuthenticatorVuePlugin, {
				options: {
					application: AbasWebApplication.TenantManagement,
					loginRedirectUri: getRedirectUri(baseUrl) + '/',
					logoutRedirectUri: getRedirectUri(baseUrl) + '/logout',
					audience: 'management',
					refreshToken: true,
				}, configuration: {
					...app.config.globalProperties.$configuration,
					clients: {
						tenantManagement: "G4UCOYHSQiHR6zvWVtoImzHw0EokwcXv"
					},
					api: {
						authBaseURL: "https://abas-federation.auth0.com"
					}
				}
			})
		}),

		await app.config.globalProperties.$auth.login();

		app.config.globalProperties.$emitter = mitt();

		app.use(router)
		app.use(store)
		// directives
		app.directive('observe-visibility',VueObserveVisibility);
		app.directive('loadable', loadable);
		app.directive('tooltip',VTooltip);

		new HttpClient().init(app, router)

		app.mount('#app');
	} catch (e) {
		createApp(ErrorApp, {
			errorMessage: e.message
		}).mount('#app');
	}
}

initializeApp();

