<template>
	<app-toolbar>
		<div class="left">
			<CreatePermission />
			<SearchBoxLight
				id="searchInput"
				:model-value="searchValue"
				data-cy="search-permissions"
				required
				placeholder="Search permissions by group or permission name"
				@update:model-value="search"
			/>
		</div>
		<div class="right">
			<tool-bar-button
				data-cy="reload"
				icon="syncAlt"
				@click="reload"
			>
				Reload list
			</tool-bar-button>
		</div>
	</app-toolbar>
</template>
<script>
import { AppToolbar, SearchBoxLight, ToolBarButton } from '@abas/elements';
import PERMISSIONS_CONSTANTS from "./Permissions.constants";

import CreatePermission from "@/pages/permissions/CreatePermission";

export default {
	components: {
		AppToolbar,
		CreatePermission,
		SearchBoxLight,
		ToolBarButton
	},
	data() {
		return {
			searchValue: "",
		};
	},
	methods: {
		search(input) {
			this.$emitter.emit(PERMISSIONS_CONSTANTS.events.searchPermissions, input);
		},
		reload() {
			this.$emitter.emit(PERMISSIONS_CONSTANTS.events.loadPermissions);
		}
	},
};
</script>
<style lang="scss" scoped>
.left {
	display: flex;
	flex-grow: 1;
}

.right {
	display: flex;
	flex-grow: 0;
}
#searchInput {
  width: 400px;
  padding: 0 4px;
}
</style>
