<template>
	<div id="app">
		{{ errorMessage }}
	</div>
</template>

<script>

export default {
	name: "ErrorApp",
	components: {
	},
	props: {
		errorMessage: {
			type: String,
			default: "",
		},
	},
};
</script>
