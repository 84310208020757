
<template>
	<div
		ref="tenantsPage"
		class="home"
	>
		<data-table
			ref="table"
			class="table"
			:responsive-columns="true"
			:items="tenantsOnPage"
			:item-height="40"
			:loading="isTableLoading"
		>
			<template
				#header="{ headerWidth }"
			>
				<DataTableHeader
					resizable
					width="120px"
					fixed-width-enable
					@click="sortColumns('infrastructure_status')"
				>
					Status
				</DataTableHeader>
				<DataTableHeader
					width="90px"
					fixed-width-enable
					@click="sortColumns('connected')"
				>
					On-/Offline
				</DataTableHeader>
				<DataTableHeader
					resizable
					:width="headerWidth"
					responsive-header
					@click="sortColumns('name')"
				>
					Tenant Name
				</DataTableHeader>
				<DataTableHeader
					width="50px"
					fixed-width-enable
					@click="sortColumns('location')"
				>
					Type
				</DataTableHeader>
				<DataTableHeader
					resizable
					:width="headerWidth"
					responsive-header
					@click="sortColumns('company')"
				>
					Company
				</DataTableHeader>
				<DataTableHeader
					resizable
					width="150px"
					fixed-width-enable
				>
					Client Agent Version
				</DataTableHeader>
				<DataTableHeader
					resizable
					width="150px"
					fixed-width-enable
					@click="sortColumns('expiry_time')"
				>
					Expiry Date
				</DataTableHeader>
				<DataTableHeader
					resizable
					:width="headerWidth"
					responsive-header
					@click="sortColumns('owner_email')"
				>
					Owner
				</DataTableHeader>
				<DataTableHeader
					width="122px"
					fixed-width-enable
				>
					Actions
				</DataTableHeader>
			</template>
			<template #rows="row">
				<DataTableCell>{{ row.item.infrastructure_status }}</DataTableCell>
				<DataTableCell>
					<Icon
						class="icon"
						:icon="connectedIcon(row.item.connected)"
					/>
				</DataTableCell>
				<DataTableCell data-cy="tenant-name">
					{{ row.item.name }}
				</DataTableCell>
				<DataTableCell>
					<Icon
						class="icon"
						:title="locationTitle(row.item.location)"
						:icon="locationIcon(row.item.location)"
					/>
				</DataTableCell>
				<DataTableCell>{{ row.item.company }} ({{ row.item.reference_number }})</DataTableCell>
				<DataTableCell style="justify-content: center;">
					{{ row.item.client_agent ? row.item.client_agent.version : "N/A" }}
				</DataTableCell>
				<DataTableCell>{{ $filters.date(row.item.expiry_time) }}</DataTableCell>
				<DataTableCell>{{ row.item.owner_email }}</DataTableCell>
				<DataTableCell class="icon">
					<IconButton
						data-cy="tenant-details"
						icon="eye"
						title="Show Tenant Details"
						@click="viewTenant(row.item.id)"
					/>
					<IconButton
						data-cy="tenant-link"
						icon="externalLinkAlt"
						title="Open tenant"
						@click="openLinkTenant(row.item.name)"
					/>
					<DeleteTenant
						:disabled="checkStatus(row.item.infrastructure_status)"
						:tenant-name="row.item.name"
						@delete-tenant="onDeleteTenant($event, row.item.id)"
					/>
				</DataTableCell>
			</template>
		</data-table>
	</div>
	<div
		v-show="showPagination"
		class="pagination"
	>
		<pagination
			:disabled-previous="isFirstPage"
			:disabled-next="isLastPage"
			@previous="currentPage--"
			@next="currentPage++"
		>
			Page {{ currentPage }} of {{ totalPages }}
		</pagination>
	</div>
</template>


<script>
/* eslint-disable sonarjs/no-duplicate-string*/

import {
	Icon,
	IconButton,
	DataTable,
	DataTableHeader,
	DataTableCell,
	Pagination,
} from "@abas/elements";
import { debounce, isUndefined } from 'lodash';

import STORE_CONSTANTS from "@/store/constants";
import { getTenantLink } from "@/utils/tenant";

import DeleteTenant from "@/pages/tenant/DeleteTenant.vue";
import DeleteTenantMixin from "@/pages/tenant/DeleteTenant.mixin";
import UpdateAllTenantsMixin from "@/pages/tenants/UpdateAllTenants.mixin";

export default {
	name: "TenantsPage",
	components: {
		Icon,
		IconButton,
		DataTable,
		DataTableHeader,
		DataTableCell,
		DeleteTenant,
		Pagination,
	},
	mixins: [ DeleteTenantMixin, UpdateAllTenantsMixin ],
	data() {
		return {
			sortColumn: "",
			sortReverse: false,
			currentPage: 1,
			totalPages: 1,
			chunkSize: 20,
			chunkedTenants: []
		};
	},
	computed: {
		isTableLoading() {
			return this.$store.getters['tenants/isTableLoading'];
		},
		isLastPage() {
			return this.currentPage === this.totalPages
		},
		isFirstPage() {
			return this.currentPage === 1
		},
		showPagination() {
			return this.totalPages > 1
		},
		tenants() {
			const result = this.$store.getters[STORE_CONSTANTS.tenants.getters.getQueryName()];
			if(this.sortColumn === "") return result;

			result.sort((a,b) => {
				// set empty string as fallback for comparison
				let aElement = !isUndefined(a[this.sortColumn]) ? a[this.sortColumn] : '';
				let bElement = !isUndefined(b[this.sortColumn]) ? b[this.sortColumn] : '';

				// special handling for column connected - values are true/false and localeCompare does not work
				if(this.sortColumn === 'connected') {
					aElement = aElement ? "icon:ball_green" : "icon:ball_red";
					bElement = bElement ? "icon:ball_green" : "icon:ball_red";
				}
				if (this.sortReverse) {
					return -aElement.localeCompare(bElement)
				}
				return aElement.localeCompare(bElement)
			})
			return result
		},
		tenantsOnPage() {
			return this.chunkedTenants.length > 0 ? this.chunkedTenants[this.currentPage - 1] : []
		}
	},
	watch: {
		tenants: {
			handler(newTenants) {
				this.prepareTable(newTenants)
			},
			immediate: true,
			deep: true
		}
	},
	created() {
		this.$store.dispatch(STORE_CONSTANTS.tenants.getters.getQueryName());
		this.$emitter.on("updateAllTenants", () => this.onUpdateAllTenants());
		this.debouncedResize = debounce(() => { this.resize() }, 200);
		window.addEventListener('resize', this.debouncedResize);
	},
	mounted() {
		this.resize();
	},
	unmounted() {
		this.$emitter.off("updateAllTenants");
		window.removeEventListener('resize', this.debouncedResize);
	},
	methods: {
		resize() {
			this.setChunkSize()
			this.prepareTable(this.tenants)
		},
		setChunkSize() {
			const space = this.$refs.tenantsPage.clientHeight || 834;
			const thHeight = 34;
			const trHeight = 40;
			this.chunkSize = Math.max(Math.floor((space - thHeight) / trHeight), 1) || 20;
		},
		prepareTable(tenants) {
			if(!tenants) return;
			// reset the current page to 1
			this.currentPage = 1
			this.chunkedTenants = this.sliceIntoChunks(tenants)
			this.totalPages = this.chunkedTenants.length
		},
		sliceIntoChunks(arr) {
			const res = [];
			for (let i = 0; i < arr.length; i += this.chunkSize) {
				const chunk = arr.slice(i, i + this.chunkSize);
				res.push(chunk);
			}
			return res;
		},
		checkStatus(tenantStatus) {
			return tenantStatus === "DELETE_IN_PROGRESS" || tenantStatus === "CREATE_IN_PROGRESS";
		},
		connectedIcon(connected) {
			return connected ? "icon:ball_green" : "icon:ball_red";
		},
		locationIcon(location) {
			return location === "ON_PREMISE" ? "warehouse" : "icon:internet";
		},
		locationTitle(location) {
			return location === "ON_PREMISE" ? "On-premises" : "Full cloud";
		},
		async onDeleteTenant(retainAuth0, tenantId) {
			try {
				await this.deleteTenant(tenantId, retainAuth0);
				this.$store.dispatch(STORE_CONSTANTS.tenants.getters.getQueryName());
			} catch (e) {
				this.$error("Cannot delete tenant. Please try again later.")
			}
		},
		async onUpdateAllTenants() {
			try {
				await this.updateAllTenants();
				this.$info(`Tenants updated successfully`);
			} catch(e) {
				this.$error(`Could not update tenants.\n${e.data?.error}`);
			}
		},
		openLinkTenant(tenantName) {
			const url = getTenantLink(tenantName);
			window.open(url);
		},
		sortColumns(columnName){
			if (this.sortColumn === columnName) {
				this.sortReverse = !this.sortReverse;
			} else {
				this.sortColumn = columnName;
				this.sortReverse = false;
			}
		},
		viewTenant(id) {
			this.$router.push(`/tenant/${id}`);
		}
	},
};
</script>

<style lang="scss" scoped>
.home {
	box-sizing: border-box;
	border: none;
	width: 100%;
	height: calc( 100% - 40px );
}

.table {
	width: 100%;
	--data-table-max-width: 100%;
}

.icon {
	box-sizing: border-box;
	display: flex;
	width: 100%;
	gap: 8px;
	align-items: center;
	justify-content: center;
}

.pagination {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 10px;
	column-gap: 5px;
}

</style>
