import axios from "axios";

export default {
	methods: {
		async getManagers(id) {
			const { data } = await axios.get(`/tenants/${id}/managers`);

			return data.users;
		},
		async tenantDownSubscription(user, isSubscribed) {
			const body = {
				subscribed_tenant_down_notification: isSubscribed,
			};

			await axios.put(`/tenants/${user.tenant}/managers/${user.id}`, body);
		},
		async removeManager(managerId, tenantId) {
			await axios.delete(`/tenants/${tenantId}/managers/${managerId}`, { data: { } });
		}
	},
};
