<template>
	<div class="add-manager">
		<ToolBarButton
			data-cy="add-manager-button"
			primary
			icon="plus"
			@click="open()"
		>
			Add manager
		</ToolBarButton>
		<SimpleDialog
			v-if="visible"
			ref="dialog"
			:fit="'none'"
			@closed="close()"
		>
			<template #header>
				<div>
					Add manager
				</div>
			</template>
			<template #body>
				<div
					class="modal-body"
					style="width: 100%; height:100%;"
				>
					<TextField
						id="managerInput"
						ref="textField"
						v-model="email"
						data-cy="add-manager-email"
						label="Manager Email"
						placeholder="Type in email of manager"
						required
						:error-message="isValid ? '' : 'Type in valid email'"
					/>
					<ToolBarButton
						primary
						data-cy="add-manager-dialog-add-button"
						class="inline-buttons"
						:disabled="!isValid"
						@click="addManager()"
					>
						Add Manager
					</ToolBarButton>
				</div>
			</template>
			<template #footer>
				<div
					class="footer"
					style="display: flex"
				>
					<ToolBarButton
						data-cy="add-manager-dialog-close-button"
						class="inline-buttons"
						@click="close()"
					>
						Close
					</ToolBarButton>
				</div>
			</template>
		</SimpleDialog>
	</div>
</template>
<script>
import {
	SimpleDialog,
	TextField,
	ToolBarButton,
} from "@abas/elements";
import axios from "axios";

import { isEmail } from "@/utils/misc";

import MANAGERS_CONSTANTS from "./Managers.constants";

const initialState = () => {
	return {
		email: "",
		visible: true,
	};
};

export default {
	components: {
		SimpleDialog,
		TextField,
		ToolBarButton,
	},
	data() {
		return initialState();
	},
	computed: {
		isValid() {
			return isEmail(this.email);
		},
		tenantId() {
			return this.$route.params.id;
		},
	},

	methods: {
		async open() {
			this.visible = true;
			await this.$nextTick();
			this.$refs.dialog.open();
			await this.$nextTick();
			this.$refs.textField.$refs.input.focus();
		},
		close() {
			Object.assign(this, initialState());
			this.$refs.dialog.close();
			this.visible = false;
		},
		async addManager() {
			try {
				await axios.post(`/tenants/${this.tenantId}/managers`, { email: this.email });
				this.$emitter.emit(MANAGERS_CONSTANTS.events.loadManagers);
				this.$info(`Manager ${this.email} added`);
			} catch (e) {
				let errorMsg = `Could not add ${this.email}. `;
				errorMsg += e.data?.error ? e.data.error : "Please try again later."
				this.$error(errorMsg);
			}
		},
	},
}
</script>
<style lang="scss" scoped>
.add-manager {
	--simple-dialog-height: 10vh;
	--simple-dialog-min-height: 160px;
	--simple-dialog-width: 40vw;
	--simple-dialog-min-width: 400px;
}

.modal-body {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
}
#managerInput {
	min-width: calc( 100% - 110px );
	padding-right: 4px;
}
</style>
