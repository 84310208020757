<template>
	<header-drop-down
		ref="menu"
		abas-user-menu
		class="dropdown"
		dark
	>
		<template #button-content>
			<div
				class="button-content"
			>
				<user-avatar
					:disabled="disabled"
					:img-url="image"
					:username="name"
				/>
				<div class="text">
					<span class="title">{{ name }}</span>
					<span class="subtitle">{{ email }}</span>
				</div>
			</div>
		</template>
		<list-menu-dark
			:items="items"
			@menu-item-clicked="itemClicked"
		/>
	</header-drop-down>
</template>

<script>
import { HeaderDropDown, ListMenuDark } from '@abas/elements';
import UserAvatar from "@/UserAvatar";

/**
 * User menu button and options
 */
export default {
	name: 'AbasUserMenu',
	components: {
		HeaderDropDown,
		ListMenuDark,
		UserAvatar,
	},
	props: {
		/**
		 * User name
		 */
		name: {
			type: String,
			default: '',
		},
		/**
		 * User email
		 */
		email: {
			type: String,
			default: '',
		},
		/**
		 * User gravatar/image
		 */
		image: {
			type: String,
			default: '',
		},
		/**
		 * Disabled
		 */
		disabled: {
			type: Boolean,
			default: false,
		},
		/**
		 * List of items to show in the dropdown (see list-item-dark for further doc)
		 */
		items: {
			type: Array,
			default: () => [],
		},
	},
	emits: ['menu-item-clicked'],
	watch: {
		items: {
			deep: true,
			handler() {
				this.$nextTick(() => {
					this.$refs.menu.setNewCoordinates();
				});
			},
		},
	},
	methods: {
		itemClicked(item) {
			this.$refs.menu.close();
			/**
			 * Dropdown menu item was clicked
			 * @event menu-item-clicked
			 */
			this.$emit('menu-item-clicked', item);
		},
	},
};
</script>

<style lang="scss" scoped>


.dropdown {
	--header-button-content-padding: 0px;
}

.button-content {
	padding: 0;
	display: grid;
	grid-template-columns: repeat(2, auto);
	align-items: center;

	.text {
		padding: 0 4px;
		display: grid;
		grid-template-rows: repeat(2, auto);
		justify-items: left;

		.subtitle {
			@include abas-font-12-normal();
			color: $basicsColor;
			white-space: nowrap;
		}

		.title {
			@include abas-font-16-semibold();
			line-height: 16px;
		}
	}
}
</style>
