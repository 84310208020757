import { createRouter, createWebHistory } from "vue-router"

import TenantsPage from "@/pages/tenants/TenantsPage";
import TenantsPageToolbar from "@/pages/tenants/TenantsPageToolbar";

import TenantPage from "@/pages/tenant/TenantPage";
import TenantPageToolbar from "@/pages/tenant/TenantPageToolbar";

import TenantManagersPage from "@/pages/tenant/managers/ManagersPage";
import TenantManagersPageToolbar from "@/pages/tenant/managers/ManagersToolbar";

import CreateTenantPage from "@/pages/create/CreateTenant";
import CreateTenantPageToolbar from "@/pages/create/CreateTenantToolbar";

import PermissionsPage from "@/pages/permissions/PermissionsPage";
import PermissionsPageToolbar from "@/pages/permissions/PermissionsToolbar";

import LogoutPage from "@/LogoutPage";


const router = createRouter({
	history: createWebHistory(),
	mode: "history",
	routes: [
		{
			path: "/",
			redirect: { name: "Tenants" }
		},
		{
			path: "/tenants",
			name: "Tenants",
			components: {
				default: TenantsPage,
				toolbar: TenantsPageToolbar,
			},
		},
		{
			path: "/tenant/:id",
			name: "Tenant details",
			components: {
				default: TenantPage,
				toolbar: TenantPageToolbar,
			},
		},
		{
			path: "/tenant/:id/managers",
			name: "Managers",
			components: {
				default: TenantManagersPage,
				toolbar: TenantManagersPageToolbar,
			},
		},
		{
			path: "/create",
			name: "Create Tenant",
			components: {
				default: CreateTenantPage,
				toolbar: CreateTenantPageToolbar,
			},
		},
		{
			path: "/permissions",
			name: "Permissions",
			components: {
				default: PermissionsPage,
				toolbar: PermissionsPageToolbar,
			},
		},
		{
			path: "/logout",
			name: "logout",
			components: {
				default: LogoutPage,
			},
		},
		{
			path: "/:catchAll(.*)",
			name: "callback",
		},
	],
});

export default router;
