<template>
	<div class="create-tenant">
		<div class="row">
			<div class="group">
				<TextField
					:model-value="tenantName"
					data-cy="tenant-name"
					class="text-field"
					label="Name"
					required
					:error-message="errorMessages.tenantName"
					@update:model-value="onInputName"
				/>
				<AutoComplete
					v-model="autoCompleteMandant"
					data-cy="company"
					class="mandant"
					label="Company/Mandant"
					:class="{'error': errorMessages.mandant}"
					style="width: 640px;"
					:items="mandants"
					:placeholder="errorMessages.mandant"
					@invalid-entry="onInvalid('mandant')"
					@item-selected="onSelected($event, 'mandant')"
				/>
				<AutoComplete
					v-model="autoCompletePurpose"
					data-cy="purpose"
					class="purpose"
					label="Purpose"
					:class="{'error': errorMessages.purpose}"
					style="width: 640px;"
					:items="purposes"
					:placeholder="errorMessages.purpose"
					@invalid-entry="onInvalid('purpose')"
					@item-selected="onSelected($event, 'purpose')"
				/>
			</div>

			<div class="group secondary">
				<AutoComplete
					v-model="autoCompleteType"
					data-cy="tenant-type"
					class="type"
					label="Type"
					:class="{'error': errorMessages.type}"
					:placeholder="errorMessages.type"
					style="width: 640px;"
					select-first
					:items="types"
					@invalid-entry="onInvalid('type')"
					@item-selected="onSelected($event, 'type')"
				/>
				<AutoComplete
					v-model="autoCompleteLifeTime"
					data-cy="expiration"
					class="lifetime"
					label="Expiration time"
					:class="{'error': errorMessages.lifeTime}"
					:placeholder="errorMessages.lifeTime"
					style="width: 640px;"
					select-first
					:items="lifeTimes"
					@invalid-entry="onInvalid('lifeTime')"
					@item-selected="onSelected($event, 'lifeTime')"
				/>
				<AutoComplete
					v-model="autoCompleteAuth0"
					data-cy="auth0-connection"
					class="auth0"
					label="Auth0 Connection"
					:class="{'error': errorMessages.auth0Connection}"
					:placeholder="errorMessages.auth0Connection"
					style="width: 640px;"
					select-first
					:items="auth0Connections"
					@invalid-entry="onInvalid('auth0Connection')"
					@item-selected="onSelected($event, 'auth0Connection')"
				/>
			</div>
		</div>
		<div
			v-show="type.value === 'managed'"
			class="row managed-instance"
		>
			<div class="group">
				<AutoComplete
					v-model="autoCompleteMachineImage"
					data-cy="machine-image"
					class="machine-image"
					label="Machine Image"
					:class="{'error': errorMessages.machineImage}"
					:placeholder="errorMessages.machineImage"
					style="width: 640px;"
					select-first
					:items="machineImages"
					@invalid-entry="onInvalid('machineImage')"
					@item-selected="onSelected($event, 'machineImage')"
				/>
				<AutoComplete
					v-model="autoCompleteInstanceType"
					data-cy="instance-type"
					class="instance-type"
					label="Instance Type"
					:class="{'error': errorMessages.instanceType}"
					:placeholder="errorMessages.instanceType"
					style="width: 640px;"
					select-first
					:items="instanceTypes"
					@invalid-entry="onInvalid('instanceType')"
					@item-selected="onSelected($event, 'instanceType')"
				/>
			</div>
		</div>

		<div
			v-show="getVal(auth0Connection, 'value') === 'oauth2'"
			class="row oauth2-config"
		>
			<div class="group">
				<div class="header">
					OAuth 2 details&nbsp;
					<span>
						<IconButton
							icon="question"
							@click="openWindow('https://tools.ietf.org/html/rfc6749')"
						/>
					</span>
				</div>
				<TextField
					:model-value="oauthDomain"
					class="text-field"
					label="OAuth host"
					placeholder="https://example.myoauthserver.com"
					required
					:error-message="errorMessages.oauthDomain"
					@update:model-value="onInput($event, 'oauthDomain')"
				/>
				<TextField
					:model-value="authorizationURL"
					class="text-field"
					label="Authorization URL"
					required
					:error-message="errorMessages.authorizationURL"
					@update:model-value="onInput($event, 'authorizationURL')"
				/>
				<TextField
					:model-value="tokenURL"
					class="text-field"
					label="Token URL"
					required
					:error-message="errorMessages.tokenURL"
					@update:model-value="onInput($event, 'tokenURL')"
				/>
				<TextField
					:model-value="userInfoURL"
					class="text-field"
					label="User Info URL"
					required
					:error-message="errorMessages.userInfoURL"
					@update:model-value="onInput($event, 'userInfoURL')"
				/>
				<TextField
					:model-value="clientId"
					class="text-field"
					label="Client ID"
					required
					:error-message="errorMessages.clientId"
					@update:model-value="onInput($event, 'clientId')"
				/>
				<TextField
					:model-value="clientSecret"
					class="text-field"
					label="Client Secret"
					required
					:error-message="errorMessages.clientSecret"
					@update:model-value="onInput($event, 'clientSecret')"
				/>
			</div>
		</div>

		<div
			v-show="getVal(auth0Connection, 'value') === 'adfs'"
			class="row oauth2-config"
		>
			<div class="group">
				<TextField
					:model-value="adfsServer"
					class="text-field"
					label="ADFS Server"
					placeholder="https://example.myadfsserver.com"
					required
					:error-message="errorMessages.adfsServer"
					@update:model-value="onInput($event, 'adfsServer')"
				/>
				<TextField
					:model-value="tenantDomain"
					class="text-field"
					label="Email Domain"
					placeholder="myemailserve.com"
					required
					:error-message="errorMessages.tenantDomain"
					@update:model-value="onInput($event, 'tenantDomain')"
				/>
			</div>
		</div>

		<div class="row">
			<TextArea
				:model-value="notes"
				class="notes"
				label="Notes"
				maxlength="255"
				placeholder="Max 255 characters long"
				:error-message="errorMessages.notes"
				@update:model-value="onInputNotes"
			/>
		</div>
	</div>
</template>
<script>
import { debounce, get } from "lodash";
import { AutoComplete, IconButton, TextArea, TextField } from "@abas/elements";

import STORE_CONSTANTS from "@/store/constants";

import CT_CONSTANTS from "./CreateTenant.constants";
import CreateTenantMixin from "./CreateTenant.mixin";

export default {
	name: "CreateTenant",
	components:{
		AutoComplete,
		IconButton,
		TextArea,
		TextField,
	},
	mixins: [CreateTenantMixin],
	data() {
		return {
			tenantName: "",
			notes: "",
			adfsServer: "",
			autoCompleteAuth0: "",
			autoCompleteInstanceType: "",
			autoCompleteLifeTime: "",
			autoCompleteMandant: "",
			autoCompleteMachineImage: "",
			autoCompletePurpose: "",
			autoCompleteType: "",
			autoCompleteOauthDomain: "",
			authorizationURL: "",
			auth0Connection: { value: null },
			/**
			 * When oauth2 is fixed in backend, add this:
			 *	{
			 *		value: "oauth2",
			 *		title: "OAuth 2",
			 *	},
			 */
			auth0Connections: [{
				value: "auth0",
				title: "Database",
			}, {
				value: "ad",
				title: "AD/LDAP",
			}, {
				value: "adfs",
				title: "ADFS",
			}],
			clientId: "",
			clientSecret: "",
			instanceType: { value: null },
			instanceTypes: [],
			lifeTime: { value: null },
			mandant: { value: null },
			machineImage: { value: null },
			machineImages: [],
			oauthDomain: "",
			purpose: { value: null },
			purposes: [{
				value: "TEST",
				title: "Test",
			}, {
				value: "DEMO",
				title: "Demo",
			}, {
				value: "LIVE",
				title: "Live",
			}],
			tenantDomain: "",
			tokenURL: "",
			type: { value: null },
			types: [{ value: "hybrid", title: "Self Hosted Instance" }, { value: "managed", title: "Managed Instance" }],
			userInfoURL: "",
			onInputNameDebounce: debounce(this.onInputNameImpl, 700),
			getVal: get
		};
	},
	computed: {
		mandants() {
			return this.$store.getters[STORE_CONSTANTS.mandants.getters.getQueryName()].map(
				x => {
					x.title = `${x.customer} (${x.referenceid})`;

					return x;
				}
			);
		},
		permissions() {
			return this.$store.getters[STORE_CONSTANTS.permissions.getters.getQueryName()] || [];
		},
		lifeTimes() {
			const times = [{
				value: 2,
				title: "2 Hours",

			}, {
				value: 24,
				title: "1 Day",

			}, {
				value: 168,
				title: "1 Week",

			}, {
				value: 720,
				title: "30 Days",

			}, {
				value: 4320,
				title: "6 months",

			}, {
				value: 0,
				title: "No Expiration",

			}];

			if (this.permissions.includes("create-extended-lifetime-tenants") ||
					(this.getVal(this.type, "value") === "hybrid")) {
				return times;
			}

			// Only allow certain life times
			return times.filter((x) => ["1 Day", "1 Week", "30 Days"].includes(x.title));
		},
	},
	watch: {
		tenantName: {
			immediate: true,
			handler(val) {
				this.errorMessages.tenantName =
					val.length < 3 ? "Minimal length is 3 symbols" : "";
			}
		},
		errorMessages: {
			immediate: true,
			deep: true,
			handler() {
				this.validateForm();
			},
		},
		auth0Connection() {
			this.validateForm();
		},
		type() {
			this.validateForm();
		}
	},
	async created() {
		await this.initialSetup();
		this.$emitter.on(CT_CONSTANTS.events.createTenant, this.createTnt.bind(this));
	},
	unmounted() {
		this.$emitter.off(CT_CONSTANTS.events.createTenant);
	},
	methods: {
		async initialSetup() {
			await this.setInstanceTypes();
			await this.setMachineImages();
		},
		async setInstanceTypes() {
			const instanceTypes = await this.getInstanceTypes();
			this.instanceTypes = instanceTypes.map(x => {
				x.title = x.name;

				return x;
			});
		},
		async setMachineImages() {
			const machineImages = await this.getMachineImages();
			this.machineImages = machineImages.map(x => {
				x.title = x.description;

				return x;
			});
		},
		onInput(val, prop) {
			this.errorMessages[prop] = val ? '' :  `Please type in ${this.snakeCaseToSpacedWords(prop)}`
		},
		onInputName(val) {
			this.onInputNameDebounce(val);
		},
		async onInputNameImpl(val) {
			const data = await this.validateTenantName(val);
			if (data.valid) {
				this.tenantName = val;
				this.errorMessages.tenantName = "";
			} else {
				this.errorMessages.tenantName = val.length < 3 ? "Minimal length is 3 symbols" : data.error_message;
			}
		},
		onInputNotes(val) {
			if (val.length < 256) {
				this.notes = val;
				this.errorMessages.notes = "";
			} else {
				this.errorMessages.notes = "Maximum allowed length is 255 characters"
			}
		},
		onInvalid(prop) {
			const propWords = this.snakeCaseToSpacedWords(prop);
			this.errorMessages[prop] = `Please select ${propWords}`;
		},
		onSelected(item, prop) {
			if(prop === 'type' && item.value === "managed") {
				this.autoCompleteLifeTime = "";
				this.lifeTime = null;
				this.onInvalid("lifeTime");
			}
			this.errorMessages[prop] = "";
			this[prop] = item;
		},
		// e.g. "dogEatDog" to "dog eat dog"
		snakeCaseToSpacedWords(val) {
			return val.split(/(?=[A-Z])/).map(x => x.toLowerCase()).join(" ");
		},
		openWindow(url) {
			window.open(url);
		},
		getRequestBody() {
			let body = {
				tenant: this.tenantName,
				reference_number: this.mandant.referenceid,
				notes: this.notes,
				purpose: this.purpose.value,
				connection_type: this.auth0Connection.value,
			};

			if (this.type.value === "managed") {
				body.tenant_location = "CLOUD";
				body.instance_type = this.instanceType.name;
				body.image_id = this.machineImage.id;
			} else {
				body.tenant_location = "ON_PREMISE";
			}

			if (this.lifeTime.value > 0) {
				body.lifetime_hrs = this.lifeTime.value;
			}

			if (this.getVal(this.auth0Connection, 'value') === "oauth2") {
				body.connection_detail = {
					authorizationURL: this.authorizationURL,
					tokenURL: this.tokenURL,
					userinfoURL: this.userInfoURL,
					clientId: this.clientId,
					clientSecret: this.clientSecret,
				}
			} else if (this.getVal(this.auth0Connection, 'value') === "adfs") {
				body.connection_detail = {
					adfsServer: this.adfsServer,
					domainAliases: this.tenantDomain,
					tenantDomain: this.tenantDomain,
				}
			}

			return body
		},
		async createTnt() {
			if (this.invalidForm) {
				throw new Error("Form is invalid!");
			}

			const body = this.getRequestBody()

			try {
				this.$store.dispatch(STORE_CONSTANTS.createTenant.getters.getDisabledName(), true);
				await this.createTenant(body);
				this.$info(`Tenant ${body.tenant} created successfully.`);
				this.$router.push("/tenants");
			} catch (e) {
				let errorMsg = `Could not create tenant. `;
				errorMsg += e.data?.error ? e.data.error : "Please try again later."
				this.$error(errorMsg);
			} finally {
				this.$store.dispatch(STORE_CONSTANTS.createTenant.getters.getDisabledName(), false);
			}
		},
	},
};
</script>
<style lang="scss" scoped>


	.row {
		display: flex;
		flex-direction: column;
	}

	.group > div {
		margin-bottom: 10px;
	}

	.group {
		margin-right: 10px;

		.header {
			display: flex;
			align-items: center;

			> span {
				margin-left: 10px;
			}
		}
	}

	.oauth2-config > .group {
		width: 640px;
	}

	.text-field {
		max-width: 640px;
	}

	.notes {
		width: 640px;
	}

	.error {
		border: 1px solid $errorColorDark;
	}

	.secondary {
		margin-top: 16px;
	}
</style>
