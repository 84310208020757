import axios from "axios";
import STORE_CONSTANTS from "./constants";
import { get } from 'lodash';

export default {
	namespaced: true,
	state: {
		tenants: [],
		search: "",
		selectedTenant: null,
		isTableLoading: false,
	},

	getters: {
		[STORE_CONSTANTS.tenants.getters.query]: (state) =>
			state.tenants.filter((tenant) => {
				if (!state.search) {
					return true;
				}
				const searchInput = get(state, 'search', '').toLowerCase()
				const name = get(tenant, 'name', '').toLowerCase()
				const email = get(tenant, 'owner_email', '').toLowerCase()
				const tenantId = get(tenant, 'id', '').toLowerCase()
				const refNumber = get(tenant, 'reference_number', '').toLowerCase()
				const company = get(tenant, 'company', '').toLowerCase()
				return name.includes(searchInput) || email.includes(searchInput) || tenantId.includes(searchInput) ||
					refNumber.includes(searchInput) || company.includes(searchInput);
			}),
		[STORE_CONSTANTS.tenants.getters.selectedTenant]: (state) => state.selectedTenant,
		isTableLoading(state) {
			return state.isTableLoading
		}
	},

	mutations: {
		[STORE_CONSTANTS.tenants.getters.query](state, payload) {
			state.tenants = payload;
		},
		[STORE_CONSTANTS.tenants.getters.search](state, payload) {
			state.search = payload;
		},
		[STORE_CONSTANTS.tenants.getters.selectedTenant](state, payload) {
			state.selectedTenant = payload;
		},
		setTableLoading(state, payload) {
			state.isTableLoading = payload;
		}
	},

	actions: {
		async [STORE_CONSTANTS.tenants.getters.query]({ commit }) {
			commit('setTableLoading', true)
			commit(STORE_CONSTANTS.tenants.getters.query, [] );

			const { data } = await axios.get("/tenants");

			commit(STORE_CONSTANTS.tenants.getters.query, data );
			commit('setTableLoading', false)
		},
		[STORE_CONSTANTS.tenants.getters.search](context, payload) {
			context.commit(STORE_CONSTANTS.tenants.getters.search, payload);
		},
		[STORE_CONSTANTS.tenants.getters.selectedTenant](context, payload) {
			context.commit(STORE_CONSTANTS.tenants.getters.selectedTenant, payload);
		},
	},
};
