<template>
	<div class="notification">
		<ToasterManager
			ref="messageBus"
			mode="top-right"
	/>
	</div>
</template>

<script>
import { ToasterManager } from "@abas/elements";
import { getCurrentInstance } from "vue";

export default {
	components: {
		ToasterManager
	},
	data() {
		return {
			messageBus: null,
		}
	},
	created() {
		/**
		 * Instance Properties
		 */
		const { appContext } = getCurrentInstance()
		appContext.config.globalProperties.$message = this.notify.bind(this);
		appContext.config.globalProperties.$info = this.info.bind(this);
		appContext.config.globalProperties.$warn = this.warning.bind(this);
		appContext.config.globalProperties.$error = this.error.bind(this);
	},
	mounted() {
		this.messageBus = this.$refs.messageBus;
	},
	methods: {
		notify(message) {
			this.messageBus.push(message.type, message.message);
		},
		info(message) {
			this.notify({type: "ok", message});
		},
		warning(message) {
			this.notify({type: "warning", message});
		},
		error(message) {
			this.notify({type: "error", message});
		}
	}
}
</script>

<style lang="scss" scoped>
.notification {
	--toaster-manager-offset: 50px;
	display: block;
}
</style>
