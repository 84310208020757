import { getActionOrGetterName } from "@/store/utils";

const NAMES = Object.freeze({
	mandants: "mandants",
	permissions: "permissions",
	createTenant: "createTenant",
	tenants: "tenants",
	editTenant: "editTenant",
	protocols: "protocols",
});

/**
* Constants for getters and actions name (usually they are the same)
*/
export default {
	mandants: {
		name: NAMES.mandants,
		getters: {
			query: "query",
			getQueryName() {
				return getActionOrGetterName(NAMES.mandants, this.query);
			},
		},
	},
	permissions: {
		name: NAMES.permissions,
		getters: {
			query: "query",
			getQueryName() {
				return getActionOrGetterName(NAMES.permissions, this.query);
			},
		},
	},
	createTenant: {
		name: NAMES.createTenant,
		getters: {
			invalidForm: "invalidForm",
			disabled: "disabled",
			getInvalidFormName() {
				return getActionOrGetterName(NAMES.createTenant, this.invalidForm);
			},
			getDisabledName() {
				return getActionOrGetterName(NAMES.createTenant, this.disabled);
			},
		}
	},
	tenants: {
		name: NAMES.tenants,
		getters: {
			query: "query",
			getQueryName() {
				return getActionOrGetterName(NAMES.tenants, this.query);
			},
			search: "search",
			getSearchName() {
				return getActionOrGetterName(NAMES.tenants, this.search);
			},
			selectedTenant: "selectedTenant",
			getSelectedTenantName() {
				return getActionOrGetterName(NAMES.tenants, this.selectedTenant);
			},
		},
	},
	editTenant: {
		name: NAMES.editTenant,
		getters: {
			queryEditState: "queryEditState",
			getQueryEditStateName() {
				return getActionOrGetterName(NAMES.editTenant, this.queryEditState);
			},
			querySaveInProgress: "querySaveInProgress",
			getQuerySaveInProgress() {
				return getActionOrGetterName(NAMES.editTenant, this.querySaveInProgress);
			},
			queryTenantDataChanged: "queryTenantDataChanged",
			queryTenantDataChangedName() {
				return getActionOrGetterName(NAMES.editTenant, this.queryTenantDataChanged);
			},
		}
	},
	protocols: {
		name: NAMES.protocols,
		getters: {
			query: "query",
			getQueryName() {
				return getActionOrGetterName(NAMES.protocols, this.query);
			},
		},
	},
};

