<template>
	<div class="create-configuration">
		<FunctionButton
			data-cy="create-new-configuration-token"
			icon="handshake"
			@click="open()"
		>
			Create new configuration
		</FunctionButton>

		<SimpleDialog
			v-if="visible"
			ref="dialog"
			:fit="'none'"
			@closed="close()"
		>
			<template #header>
				<div
					data-cy="create-configuration-dialog-header"
					class="header"
				>
					<div>
						Create new configuration
					</div>
				</div>
			</template>
			<template #body>
				<div
					data-cy="create-configuration-dialog-body"
					style="width: 100%; height:100%; overflow-y: auto"
				>
					<div>
						Create new configuration for tenant <strong>{{ tenant.name }}</strong>
					</div>
					<div v-if="!configCreated">
						<p>
							This will invalidate all previously generated configuration!<br>
							Are you sure?
						</p>
					</div>
					<div v-else>
						<div>
							<p>
								All previous ones are invalidated.<br>
								Configuration has been copied to clipboard.
							</p>
						</div>
						<TextArea
							ref="textArea"
							data-cy="create-configuration-token-area"
							label="Client configuration"
							readonly
							:rows="'5'"
							:model-value="config"
						/>
					</div>
				</div>
			</template>
			<template #footer>
				<div
					data-cy="create-configuration-dialog-footer"
					class="footer"
					style="display: flex"
				>
					<ToolBarButton
						:primary="configCreated"
						class="inline-buttons"
						@click="close()"
					>
						{{ configCreated ? 'Close' : 'No' }}
					</ToolBarButton>
					<ToolBarButton
						v-if="!configCreated"
						primary
						class="inline-buttons"
						@click="createConfiguration()"
					>
						Yes
					</ToolBarButton>
				</div>
			</template>
		</SimpleDialog>
	</div>

</template>
<script>
import {
	FunctionButton,
	SimpleDialog,
	TextArea,
	ToolBarButton
} from "@abas/elements";

import EditTenantMixin from "./EditTenant.mixin";

const initialState = () => {
	return {
		config: "",
		configCreated: false,
		visible: true,
	};
};

export default {
	components: {
		FunctionButton,
		SimpleDialog,
		TextArea,
		ToolBarButton,
	},
	mixins: [ EditTenantMixin ],
	props: {
		tenant: {
			type: Object,
			required: true,
			default() {
				return {};
			},
		},
	},
	data() {
		return initialState();
	},
	methods: {
		async open() {
			this.visible = true;
			await this.$nextTick();
			this.$refs.dialog.open();
		},
		close() {
			Object.assign(this, initialState());
			this.$refs.dialog.close();
			this.visible = false;
		},
		async createConfiguration() {
			const config = await this.createClientConfig(this.tenant.id);

			this.config = config;
			this.configCreated = true;
			await this.$nextTick();

			const el = this.$refs.textArea.$refs.input;

			el.select();
			document.execCommand("copy");
		},
	},
};
</script>
<style lang="scss" scoped>
.create-configuration {
	--simple-dialog-height: 300px;
	--simple-dialog-width: 670px;
}
</style>
