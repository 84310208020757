<template>
	<div class="delete-tenant">
		<IconButton
			v-if="iconOnly"
			:disabled="disabled"
			class="icon"
			icon="trashAlt"
			title="Delete Tenant"
			data-cy="delete-tenant-icon-button"
			@click="open"
		/>
		<ToolBarButton
			v-else
			:disabled="disabled"
			icon="trashAlt"
			data-cy="delete-tenant-toolbar-button"
			@click="open"
		>
			Delete tenant
		</ToolBarButton>
		<SimpleDialog
			v-if="visible"
			ref="dialog"
			fit="none"
			@closed="close()"
		>
			<template #header>
				<div class="header">
					Confirm delete tenant {{ tenantName }}
				</div>
			</template>
			<template #body>
				<div
					style="width: 100%; height:100%; overflow-y: auto"
				>
					<div>In order to delete tenant <b>{{ tenantName }}</b> please type in the tenant name below and press Delete:</div>
					<TextField
						v-model="tenantInput"
						data-cy="delete-tenant-input"
						class="text-field"
					/>
					<CheckBox v-model:checked="retainAuth0">
						Retain Auth0 connection
					</CheckBox>
				</div>
			</template>
			<template #footer>
				<div
					class="footer"
					style="display: flex"
				>
					<ToolBarButton
						primary
						class="inline-buttons"
						:disabled="deleteDisabled"
						@click="deleteTenant()"
					>
						Delete
					</ToolBarButton>
					<ToolBarButton
						class="inline-buttons"
						@click="close()"
					>
						Close
					</ToolBarButton>
				</div>
			</template>
		</SimpleDialog>
	</div>
</template>
<script>
import {
	CheckBox,
	IconButton,
	SimpleDialog,
	TextField,
	ToolBarButton,
} from "@abas/elements";

const initialState = () => {
	return {
		retainAuth0: false,
		tenantInput: "",
		visible: true,
	};
};

export default {
	components: {
		CheckBox,
		IconButton,
		SimpleDialog,
		TextField,
		ToolBarButton,
	},
	props: {
		disabled: {
			type: Boolean,
			required: true,
		},
		tenantName: {
			type: String,
			required: true,
		},
		iconOnly: {
			type: Boolean,
			required: false,
			default: true,
		}
	},
	emits: ['delete-tenant'],
	data() {
		return initialState();
	},
	computed: {
		deleteDisabled() {
			return this.tenantName !== this.tenantInput;
		},
	},
	methods: {
		deleteTenant() {
			this.$emit("delete-tenant", this.retainAuth0);
			this.close();
		},
		async open() {
			this.visible = true;
			await this.$nextTick();
			this.$refs.dialog.open();
		},
		close() {
			Object.assign(this, initialState());
			this.$refs.dialog.close();
			this.visible = false;
		},
	},
}
</script>
<style lang="scss" scoped>
.delete-tenant {
	--simple-dialog-height: 250px;
	--simple-dialog-width: 400px;
}

.header {
	color: $secondaryColor;
	width: 350px; // --simple-dialog-width -50 (padding etc)
	overflow: hidden;
	white-space: nowrap;
	text-overflow: clip;
}

.text-field {
	margin: 8px 0;
}
</style>
