import axios from "axios";
import STORE_CONSTANTS from "./constants";

export default {
	namespaced: true,
	state: {
		protocols: [],
	},

	getters: {
		[STORE_CONSTANTS.protocols.getters.query]: (state) => state.protocols,
	},

	mutations: {
		[STORE_CONSTANTS.protocols.getters.query](state, payload) {
			state.protocols = payload;
		},
	},

	actions: {
		async [STORE_CONSTANTS.protocols.getters.query](context) {
			const { data } = await axios.get("/cloudconnect/protocols");

			context.commit(STORE_CONSTANTS.protocols.getters.query, data.protocol);
		},
	},
};