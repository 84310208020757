import STORE_CONSTANTS from "./constants";

export default {
	namespaced: true,
	state: {
		invalidForm: true,
		disabled: false,
	},

	getters: {
		[STORE_CONSTANTS.createTenant.getters.invalidForm]: state => state.invalidForm,
		[STORE_CONSTANTS.createTenant.getters.disabled]: state => state.invalidForm || state.disabled,
	},

	mutations: {
		[STORE_CONSTANTS.createTenant.getters.invalidForm](state, payload) {
			state.invalidForm = payload;
		},
		[STORE_CONSTANTS.createTenant.getters.disabled](state, payload) {
			state.disabled = payload;
		}
	},

	actions: {
		[STORE_CONSTANTS.createTenant.getters.invalidForm](context, payload) {
			context.commit(STORE_CONSTANTS.createTenant.getters.invalidForm, payload);
		},
		[STORE_CONSTANTS.createTenant.getters.disabled](context, payload) {
			context.commit(STORE_CONSTANTS.createTenant.getters.disabled, payload);
		}
	},
};
