<template>
	<app-toolbar>
		<div class="left">
			<tool-bar-button
				primary
				data-cy="create"
				icon="plus"
				@click="create"
			>
				New tenant
			</tool-bar-button>
			<SearchBoxLight
				id="searchInput"
				v-model="searchValue"
				required
				data-cy="search"
				placeholder="Search tenants by name, owner, id or client id"
				@update:model-value="search"
			/>
		</div>
		<div class="right">
			<tool-bar-button
				v-if="mayUpdateTenant"
				icon="history"
				class="updateAllButton"
				@click="confirmationUpdateAllOpen"
			>
				Update all
			</tool-bar-button>
			<confirmation-dialog
				ref="confirmationUpdateAll"
				ok-label="Yes"
				cancel-label="Cancel"
				no-close-on-esc
				no-close-on-outside-click
				no-top-right-cross
				@ok="updateAll(true)"
				@cancel="updateAll(false)"
			>
				<template #header>
					<div>
						Update all tenants
					</div>
				</template>
				<template #body>
					<div>
						Are you sure you want to update all tenants?
					</div>
				</template>
			</confirmation-dialog>
			<tool-bar-button
				data-cy="reload"
				icon="syncAlt"
				@click="reload"
			>
				Reload list
			</tool-bar-button>
		</div>
	</app-toolbar>
</template>

<script>

import { AppToolbar, ConfirmationDialog, SearchBoxLight, ToolBarButton } from '@abas/elements';
import STORE_CONSTANTS from "@/store/constants";

export default {
	name: 'TenantsPageToolbar',
	components: {
		AppToolbar,
		ConfirmationDialog,
		SearchBoxLight,
		ToolBarButton,
	},
	data(){
		return {
			searchValue: ""
		}
	},
	computed: {
		mayUpdateTenant() {
			return this.userPermissions.includes("update-tenant")
		},
		userPermissions() {
			return this.$store.getters[STORE_CONSTANTS.permissions.getters.getQueryName()] || [];
		}
	},
	unmounted() {
		this.search("");
	},
	methods: {
		confirmationUpdateAllOpen() {
			this.$refs.confirmationUpdateAll.open();
		},
		create() {
			this.$router.push("/create");
		},
		reload() {
			this.$store.dispatch(STORE_CONSTANTS.tenants.getters.getQueryName());
		},
		search(input) {
			this.searchValue = input
			this.$store.dispatch(STORE_CONSTANTS.tenants.getters.getSearchName(), input);
		},
		updateAll(confirmed) {
			this.$refs.confirmationUpdateAll.close();
			if (confirmed) {
				this.$emitter.emit("updateAllTenants");
			}
		}
	},

};
</script>

<style lang="scss" scoped>
.left {
	display: flex;
	flex-grow: 1;
}

.right {
	display: flex;
	flex-grow: 0;
}

:deep(.updateAllButton) .fa-history {
	transform: scaleX(-1);
}
#searchInput {
  width: 350px;
  padding: 0 4px;
}
</style>
