<template>
	<div class="create-permission">
		<ToolBarButton
			primary
			data-cy="create-permission"
			icon="plus"
			@click="open()"
		>
			Create permission
		</ToolBarButton>
		<SimpleDialog
			v-if="visible"
			ref="dialog"
			:fit="'none'"
			@closed="close()"
		>
			<template #header>
				Create group permission
			</template>
			<template #body>
				<div
					class="modal-body"
					style="width: 100%; height:100%;"
				>
					<TextField
						ref="textField"
						v-model="group"
						data-cy="permission-group"
						label="Group"
						placeholder="Type in group name"
						required
						:error-message="!group ? 'Group cannot be empty' : ''"
					/>
					<TextField
						ref="textField"
						v-model="permission"
						data-cy="permission-permission"
						label="Permission"
						placeholder="Type in permission name"
						required
						:error-message="!permission ? 'Permission cannot be empty' : ''"
					/>
				</div>
			</template>
			<template #footer>
				<div
					class="footer"
					style="display: flex"
				>
					<ToolBarButton
						data-cy="save-permission"
						primary
						class="inline-buttons"
						:disabled="!isValid"
						@click="createPermission()"
					>
						Create permission
					</ToolBarButton>
					<ToolBarButton
						class="inline-buttons"
						@click="close()"
					>
						Close
					</ToolBarButton>
				</div>
			</template>
		</SimpleDialog>
	</div>
</template>
<script>
import {
	SimpleDialog,
	TextField,
	ToolBarButton,
} from "@abas/elements";

import axios from "axios";

import PERMISSIONS_CONSTANTS from "./Permissions.constants";

const initialState = () => {
	return {
		group: "",
		permission: "",
		visible: true,
	}
};

export default {
	components: {
		SimpleDialog,
		TextField,
		ToolBarButton,
	},
	data() {
		return initialState();
	},
	computed: {
		isValid() {
			return this.group && this.permission;
		},
	},

	methods: {
		async open() {
			this.visible = true;
			await this.$nextTick();
			this.$refs.dialog.open();
			await this.$nextTick();
			this.$refs.textField.$refs.input.focus();
		},
		close() {
			Object.assign(this, initialState());
			this.$refs.dialog.close();
			this.visible = false;
		},
		async createPermission() {
			try {
				await axios.put("admin/group-permissions", { group: this.group, permission: this.permission });
				this.$emitter.emit(PERMISSIONS_CONSTANTS.events.loadPermissions);
				this.$info("Permission added");
				this.close();
			} catch (e) {
				this.$error("Could not create group permission. Please try again later");
			}
		},
	},
}
</script>
<style lang="scss" scoped>
.create-permission {
	--simple-dialog-height: 20vh;
	--simple-dialog-width: 40vh;
}

.modal-body {
	display: flex;
	flex-direction: column;
}
</style>
