import axios from "axios";

import STORE_CONSTANTS from "./constants";

export default {
	namespaced: true,
	state: {
		permissions: [],
	},

	getters: {
		[STORE_CONSTANTS.permissions.getters.query]: state => state.permissions,
	},

	mutations: {
		[STORE_CONSTANTS.permissions.getters.query](state, payload) {
			state.permissions = payload;
		}
	},

	actions: {
		async [STORE_CONSTANTS.permissions.getters.query](context) {
			const { data } = await axios.get("/permissions");

			context.commit(STORE_CONSTANTS.permissions.getters.query, data);
		}
	},
};
