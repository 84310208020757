<template>
	<div class="delete-manager">
		<IconButton
			data-cy="remove-manager-button"
			class="icon"
			icon="trashAlt"
			@click="open"
		/>
		<SimpleDialog
			v-if="visible"
			ref="dialog"
			:fit="'none'"
			@closed="close()"
		>
			<template #header>
				<div>
					Remove manager
				</div>
			</template>
			<template #body>
				<div
					style="display: flex"
				>
					Do you want to remove manager {{ managerEmail }}?
				</div>
			</template>
			<template #footer>
				<div
					class="footer"
					style="display: flex"
				>
					<ToolBarButton
						data-cy="remove-manager-confirm-button"
						primary
						class="inline-buttons"
						@click="removeManager()"
					>
						Yes
					</ToolBarButton>
					<ToolBarButton
						class="inline-buttons"
						@click="close()"
					>
						No
					</ToolBarButton>
				</div>
			</template>
		</SimpleDialog>
	</div>
</template>
<script>
import {
	IconButton,
	SimpleDialog,
	ToolBarButton,
} from "@abas/elements";

export default {
	components: {
		IconButton,
		SimpleDialog,
		ToolBarButton,
	},
	props: {
		managerEmail: {
			type: String,
			required: true,
		},
	},
	emits: ['remove-manager'],
	data() {
		return {
			visible: true,
		};
	},
	methods: {
		removeManager() {
			this.$emit("remove-manager");
			this.close();
		},
		async open() {
			this.visible = true;
			await this.$nextTick();
			this.$refs.dialog.open();
		},
		close() {
			this.$refs.dialog.close();
			this.visible = false;
		},
	},
}
</script>
<style lang="scss" scoped>
.delete-manager {
	--simple-dialog-height: 20vh;
	--simple-dialog-width: 40vh;
}
</style>
