<template>
	<app-toolbar>
		<div class="left">
			<AddManager />
			<SearchBoxLight
				id="searchInput"
				v-model="searchValue"
				data-cy="search-manager"
				required
				placeholder="Search Managers by name or email"
				@update:model-value="search"
			/>
		</div>
		<div class="right">
			<tool-bar-button
				data-cy="reload"
				icon="syncAlt"
				@click="reload"
			>
				Reload
			</tool-bar-button>
			<tool-bar-button
				@click="goToTenant"
			>
				Go Back To Tenant Page
			</tool-bar-button>
		</div>
	</app-toolbar>
</template>
<script>
import {
	AppToolbar,
	SearchBoxLight,
	ToolBarButton
} from '@abas/elements';

import AddManager from "./AddManager";
import MANAGERS_CONSTANTS from "./Managers.constants";

export default {
	components: {
		AddManager,
		AppToolbar,
		SearchBoxLight,
		ToolBarButton
	},
	data() {
		return {
			searchValue: "",
		};
	},
	computed: {
		tenantId() {
			return this.$route.params.id;
		}
	},
	methods: {
		reload() {
			this.$emitter.emit(MANAGERS_CONSTANTS.events.loadManagers);
		},
		goToTenant() {
			this.$router.push(`/tenant/${this.tenantId}`);
		},
		search(input) {
			this.searchValue = input
			this.$emitter.emit(MANAGERS_CONSTANTS.events.searchManager, input);
		}
	}
}
</script>
<style lang="scss" scoped>
.left {
	display: flex;
	flex-grow: 1;
}

.right {
	display: flex;
	flex-grow: 0;
}
#searchInput {
  width: 400px;
  padding: 0 4px;
}
</style>
