<template>
	<div class="permissions">
		<LoadingOverlay v-if="loading" />
		<DataTable
			v-else
			data-cy="permissions-table"
			:items="filteredPermissions"
			:item-height="40"
		>
			<template #header>
				<DataTableHeader
					resizable
					width="400px"
				>
					Group
				</DataTableHeader>
				<DataTableHeader
					resizable
					width="300px"
				>
					Permission
				</DataTableHeader>
				<DataTableHeader
					resizable
					width="100px"
				>
					Actions
				</DataTableHeader>
			</template>
			<template #rows="row">
				<DataTableCell>{{ row.item.group }}</DataTableCell>
				<DataTableCell>{{ row.item.permission }}</DataTableCell>
				<DataTableCell :data-cy="row.item.group + '-' + row.item.permission">
					<DeletePermission
						data-cy="delete-permission-action"
						:group-permission="row.item"
						@delete-permission="onDeletePermission(row.item)"
					/>
				</DataTableCell>
			</template>
		</DataTable>
	</div>
</template>
<script>
import axios from "axios";

import {
	DataTable,
	DataTableHeader,
	DataTableCell,
	LoadingOverlay,
} from "@abas/elements";

import DeletePermission from "./DeletePermission.vue";

import STORE_CONSTANTS from "@/store/constants";

import PERMISSIONS_CONSTANTS from "./Permissions.constants";

export default {
	components: {
		DataTable,
		DataTableHeader,
		DataTableCell,
		DeletePermission,
		LoadingOverlay,
	},
	data() {
		return {
			groupPermissions: [],
			loading: true,
			search: "",
		};
	},
	computed: {
		permissions() {
			return this.$store.getters[STORE_CONSTANTS.permissions.getters.getQueryName()] || [];
		},
		filteredPermissions() {
			if (!this.groupPermissions) {
				return [];
			}
			if (!this.search) {
				return this.groupPermissions;
			}
			return this.groupPermissions.filter((perm) => perm.group.includes(this.search) ||
				perm.permission.includes(this.search));
		}
	},
	async mounted() {
		const allowed = this.permissions.includes("manage-group-permissions");

		if (!allowed) {
			this.$error("Access denied!");

			this.$router.push("/");
		} else {
			await this.loadPermissions();
		}
		this.$emitter.on(PERMISSIONS_CONSTANTS.events.searchPermissions, (input) => this.search = input);
		this.$emitter.on(PERMISSIONS_CONSTANTS.events.loadPermissions, async () => await this.loadPermissions());
	},
	unmounted() {
		this.$emitter.off(PERMISSIONS_CONSTANTS.events.searchPermissions);
		this.$emitter.off(PERMISSIONS_CONSTANTS.events.loadPermissions);
	},
	methods: {
		async loadPermissions() {
			this.loading = true;

			try {
				const { data } = await axios.get("/admin/group-permissions");

				this.groupPermissions = data;
			} catch (e) {
				this.$error("Could not load group permissions. Please try again later.");
			} finally {
				this.loading = false;
			}
		},
		async onDeletePermission(groupPermission) {
			try {
				await axios.delete("/admin/group-permissions", { data : groupPermission });

				this.$info(`Permission ${groupPermission.permission} for group ${groupPermission.group} deleted`);
				await this.loadPermissions();
			} catch (e) {
				this.$error("Could not delete permission. Please try again later.");
			}
		}
	},
}
</script>
