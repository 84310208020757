import axios from "axios";
import { get } from 'lodash';

import STORE_CONSTANTS from "@/store/constants";

export default {
	data() {
		return {
			errorMessages: {
				tenantName: "",
				notes: "",
				mandant: "Please select mandant",
				purpose: "Please select purpose",
				machineImage: "Please select machine image",
				instanceType: "Please select instance type",
				lifeTime: "Please select life time",
				auth0Connection: "Please select auth0 connection",
				type: "Please select type",
				oauthDomain: "Please type in oauth domain",
				authorizationURL: "Please type in authorization url",
				tokenURL: "Please type in token url",
				userInfoURL: "Please type in user info url",
				clientId: "Please type in client id",
				clientSecret: "Please type in client secret",
				adfsServer: "Please type in adfs server",
				tenantDomain: "Please type in tenant domain",
			},
			invalidForm: true,
		}
	},
	methods: {
		async validateTenantName(name) {
			try {
				const { data } = await axios.get(`/validate-tenant-name?name=${name}`);
				return data;
			} catch (e) {
				this.$error("Cannot validate tenant name. Please try again later.")
			}
		},
		async getInstanceTypes() {
			try {
				const { data } = await axios.get(`/instance-types`);
				return data;
			} catch (e) {
				this.$error("Cannot get instance types. Please try again later.");
			}
		},
		async getMachineImages() {
			try {
				// Vertical types are deprecated and will be removed
				return await axios.get(`/machine-images`).then((response) => response.data.filter((x) => x.type !== "SAAS_VERTICAL"));
			} catch (e) {
				this.$error("Cannot get machine images. Please try again later.");
			}
		},
		async createTenant(body) {
			return await axios.post(`/tenants`, body);
		},
		validateForm() {
			let result = false;
			result = this.checkFields(result, "mainFields")
			result = get(this.type, "value") === "managed" ? this.checkFields(result, "managedTypeFields") : result;
			result = get(this.auth0Connection, "value") === "oauth2" ? this.checkFields(result, "oauth2Fields") : result;
			result = get(this.auth0Connection, "value") === "adfs" ? this.checkFields(result, "adfsFields") : result;

			this.$store.dispatch(STORE_CONSTANTS.createTenant.getters.getInvalidFormName(), result);
			this.invalidForm = result;
		},
		checkFields(result, fields) {
			const fieldsObj = {
				mainFields: ["tenantName", "name", "notes", "mandant", "purpose", "lifeTime", "type", "auth0Connection"],
				managedTypeFields: ["machineImage", "instanceType"],
				oauth2Fields: ["oauthDomain", "authorizationURL", "tokenURL", "userInfoURL", "clientId", "clientSecret"],
				adfsFields: ["adfsServer", "tenantDomain"]
			}
			for (const field of fieldsObj[fields]) {
				if (this.errorMessages[field]) {
					result = true;
					break;
				}
			}
			return result;
		}
	}
}
