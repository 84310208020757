import axios from 'axios';

class HttpClient {

	init(app, router) {
		axios.defaults.baseURL = `${app.config.globalProperties.$configuration.api.mgmt.tenant}/v1`;
		axios.defaults.headers.post["Content-Type"] = "application/json";
		axios.defaults.headers.put["Content-Type"] = "application/json";
		axios.interceptors.request.use(
			function (config) {
				if (app.config.globalProperties.$auth.accessToken) {
					config.headers["Authorization"] =
						"Bearer " + app.config.globalProperties.$auth.accessToken;
				} else {
					return Promise.reject("AccessToken missing. Please login.");
				}
				return config;
			},
			function (error) {
				return Promise.reject(error);
			}
		);
		// Add a response interceptor for handling 401
		axios.interceptors.response.use(
			(response) => {
				// Any status code that lie within the range of 2xx cause this function to trigger
				// Do something with response data
				return response;
			},
			(error) => {
				/**
				 * Error handling
				 * https://axios-http.com/docs/handling_errors
				 */
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.debug(error.response);
					if (401 === error.response.status) {
						router.push("/logout");
						return Promise.resolve(error.response);
					} else {
						return Promise.reject({
							status: error.response.status,
							httpStatus: error.response.data.httpStatus,
							message: error.response.data.message,
						});
					}
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.debug(error.request);
					return Promise.reject(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.debug(error.message);
					return Promise.reject({ message: error.message });
				}
			}
		);
	}
}

export { HttpClient }