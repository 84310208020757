<template>
	<div class="delete-permissions">
		<IconButton
			icon="trashAlt"
			@click="open"
		/>
		<SimpleDialog
			v-if="visible"
			ref="dialog"
			:fit="'none'"
			@closed="close()"
		>
			<template #header>
				<div
					class="header"
				>
					<div id="deleteGroupText">
						Delete group permission
					</div>
				</div>
			</template>
			<template #body>
				<div
					style="width: 100%; height:100%; overflow-y: auto"
				>
					<div>
						In order to delete group {{ groupPermission.group }} with permission {{ groupPermission.permission }}
						<span>please type in the group and permission below and press Delete:</span>
					</div>
					<TextField
						v-model="groupInput"
						data-cy="delete-group"
						label="Group"
						class="text-field"
					/>
					<TextField
						v-model="permissionInput"
						data-cy="delete-permission"
						label="Permission"
						class="text-field"
					/>
				</div>
			</template>
			<template #footer>
				<div
					class="footer"
					style="display: flex"
				>
					<ToolBarButton
						data-cy="confirm-delete-permission"
						primary
						class="inline-buttons"
						:disabled="deleteDisabled"
						@click="deletePermission()"
					>
						Delete
					</ToolBarButton>
					<ToolBarButton
						class="inline-buttons"
						@click="close()"
					>
						Close
					</ToolBarButton>
				</div>

			</template>
		</SimpleDialog>
	</div>
</template>
<script>
import {
	IconButton,
	SimpleDialog,
	TextField,
	ToolBarButton,
} from "@abas/elements";

const initialState = () => {
	return {
		groupInput: "",
		permissionInput: "",
		visible: true,
	};
};

export default {
	components: {
		IconButton,
		SimpleDialog,
		TextField,
		ToolBarButton,
	},
	props: {
		groupPermission: {
			type: Object,
			required: true,
		},
	},
	emits: ['delete-permission'],
	data() {
		return initialState();
	},
	computed: {
		deleteDisabled() {
			return this.groupPermission.group !== this.groupInput ||
				this.groupPermission.permission !== this.permissionInput;
		},
	},
	methods: {
		deletePermission() {
			this.$emit("delete-permission");
			this.close();
		},
		async open() {
			this.visible = true;
			await this.$nextTick();
			this.$refs.dialog.open();
		},
		close() {
			Object.assign(this, initialState());
			this.$refs.dialog.close();
			this.visible = false;
		},
	},
}
</script>
<style lang="scss" scoped>
.delete-permissions {
	--simple-dialog-height: 300px;
	--simple-dialog-width: 500px;
}

.text-field {
	margin: 8px 0;
}
#deleteGroupText {
	color: $secondaryColor;
}
</style>
