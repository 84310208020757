<template>
	<div class="managers">
		<LoadingOverlay v-if="loading" />
		<DataTable
			v-else
			:items="filteredUsers"
			:item-height="40"
		>
			<template #header>
				<DataTableHeader
					resizable
					width="300px"
				>
					Email
				</DataTableHeader>
				<DataTableHeader
					resizable
					width="300px"
				>
					User
				</DataTableHeader>
				<DataTableHeader
					title="Subscribe to emails when the tenant is down"
					width="200px"
				>
					Tenant Down Subscription
				</DataTableHeader>
				<DataTableHeader
					title="Remove Manager from Tenant"
					width="100px"
				>
					Remove Manager from Tenant
				</DataTableHeader>
			</template>
			<template #rows="row">
				<DataTableCell>{{ row.item.email }}</DataTableCell>
				<DataTableCell>{{ row.item.name }}</DataTableCell>
				<DataTableCell>
					<CheckBox
						:checked="row.item.subscribed_tenant_down_notification"
						@update:checked="onTenantDownChange(row.item, $event)"
					/>
				</DataTableCell>
				<DataTableCell>
					<RemoveManager
						:manager-email="row.item.email"
						@remove-manager="onRemoveManager(row.item.id)"
					/>
				</DataTableCell>
			</template>
		</DataTable>
	</div>
</template>
<script>
import {
	CheckBox,
	DataTable,
	DataTableHeader,
	DataTableCell,
	LoadingOverlay,
} from "@abas/elements";

import RemoveManager from "./RemoveManager.vue";
import ManagersMixin from "./Managers.mixin";
import MANAGERS_CONSTANTS from "./Managers.constants";

export default {
	components: {
		CheckBox,
		DataTable,
		DataTableHeader,
		DataTableCell,
		LoadingOverlay,
		RemoveManager,
	},
	mixins: [ ManagersMixin ],
	data() {
		return {
			loading: true,
			search: "",
			users: [],
		};
	},
	computed: {
		filteredUsers() {
			if (!this.search) {
				return this.users;
			}
			return this.users.filter((user) => user.email.toLowerCase().includes(this.search)
				|| user.name.toLowerCase().includes(this.search));
		},
		tenantId() {
			return this.$route.params.id;
		},
	},
	created() {
		this.$emitter.on(MANAGERS_CONSTANTS.events.loadManagers, async () => await this.loadManagers());
		this.$emitter.on(MANAGERS_CONSTANTS.events.searchManager, (val) => this.search = val);
	},
	async mounted() {
		await this.loadManagers();
	},
	unmounted() {
		this.$emitter.off(MANAGERS_CONSTANTS.events.loadManagers);
		this.$emitter.off(MANAGERS_CONSTANTS.events.searchManager);
	},
	methods: {
		async loadManagers() {
			this.loading = true;

			try {
				this.users = await this.getManagers(this.tenantId);
			} catch (e) {
				this.$error("Cannot load users. Please try again later.");
			} finally {
				this.loading = false;
			}
		},
		async onTenantDownChange(user, isSubscribed) {
			this.loading = true;

			try {
				await this.tenantDownSubscription(user, isSubscribed);
				await this.loadManagers();
			} catch (e) {
				this.$error("Cannot change subscription setting. Please try again later.");
			} finally {
				this.loading = false;
			}
		},
		async onRemoveManager(id) {
			this.loading = true;

			try {
				await this.removeManager(id, this.tenantId);
				await this.loadManagers();
			} catch (e) {
				this.$error("Cannot remove manager. Please try again later.");
			} finally {
				this.loading = false;
			}
		},
	},
};

</script>
<style lang="scss" scoped>
.managers {
	--data-table-body-max-height: calc(100vh - 105px);
}
</style>
