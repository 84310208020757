import { createStore } from "vuex";
import STORE_CONSTANTS from "./constants";
import createTenant from "./createTenant";
import mandants from "./mandants";
import permissions from "./permissions";
import tenants from "./tenants";
import protocols from "./protocols";
import editTenant from "./editTenant";

const store = createStore({
	modules: {
		[STORE_CONSTANTS.mandants.name]: mandants,
		[STORE_CONSTANTS.permissions.name]: permissions,
		[STORE_CONSTANTS.createTenant.name]: createTenant,
		[STORE_CONSTANTS.tenants.name]: tenants,
		[STORE_CONSTANTS.protocols.name]: protocols,
		[STORE_CONSTANTS.editTenant.name]: editTenant,
	},
	devtools: true
});

export default store;