<template>
	<div class="toolbar">
		<tool-bar-button
			data-cy="create-tenant-button"
			primary
			icon="save"
			:disabled="disabled"
			@click="createTenant"
		>
			Create
		</tool-bar-button>
		<tool-bar-button @click="dismiss">
			Go Back To Tenants List
		</tool-bar-button>
	</div>
</template>

<script>
import { ToolBarButton } from "@abas/elements";

import STORE_CONSTANTS from "@/store/constants";

import CT_CONSTANTS from "./CreateTenant.constants";

export default {
	name: "TenantPageToolbar",
	components: {
		ToolBarButton
	},
	computed: {
		disabled() {
			return this.$store.getters[STORE_CONSTANTS.createTenant.getters.getDisabledName()];
		},
	},
	methods: {
		createTenant() {
			this.$emitter.emit(CT_CONSTANTS.events.createTenant);
		},
		dismiss() {
			this.$router.push("/tenants");
		},
	}
};
</script>
<style lang="scss" scoped>
.toolbar {
	display: flex;
}
</style>
