<template>
	<div>
		<div
			class="flexbox-row"
			:class="getClass()"
		>
			<div
				class="cloud-connect-icon"
				:class="getIconAreaClass()"
			>
				<div class="icon-circle">
					<Icon
						:icon="getIcon()"
						:class="getIconClass()"
					/>
				</div>
			</div>
			<div
				ref="textField"
				class="cloud-connect-textarea"
			>
				{{ getTooltipText() }}
			</div>
		</div>
	</div>
</template>
<script>
import {
	Icon
} from "@abas/elements";

import STORE_CONSTANTS from "@/store/constants";

export default {
	components: {
		Icon
	},
	props: {
		tenantName: {
			type: String,
			required: true,
		},
		protocol: {
			type: String,
			required: true,
		},
	},
	computed: {
		supportedProtocols() {
			return this.$store.getters[STORE_CONSTANTS.protocols.getters.getQueryName()];
		},
	},
	methods: {
		getTooltipText() {
			const currentState = this.cloudConnectState();

			switch (currentState) {
				case "ERROR":
					return `Your cloud connect software for ${this.tenantName} uses an incompatible protocol.\nYou have to update cloud connect!`;
				case "OK":
					return `Your cloud connect software for ${this.tenantName} uses the latest protocol.\nYou are ready to go!`;
				case "OLD":
					return `Your cloud connect software for ${this.tenantName} uses an older protocol, or has never connected.`;
				case "UNKNOWN":
					return `Your cloud connect software for ${this.tenantName} is not yet connected.\nWe can't tell you if it is up to date.`
			}
		},
		getIcon() {
			const currentState = this.cloudConnectState();

			switch (currentState) {
				case "ERROR":
					return "exclamation";
				case "OK":
					return "check";
				case "OLD":
					return "info";
				case "UNKNOWN":
					return "exclamationTriangle";
			}
		},
		getClass() {
			const currentState = this.cloudConnectState();
			return currentState.toLowerCase();
		},
		getIconClass() {
			const currentState = this.cloudConnectState();
			return 'icon-' + currentState.toLowerCase();
		},
		getIconAreaClass() {
			const currentState = this.cloudConnectState();
			return 'icon-' + currentState.toLowerCase() + '-area';
		},
		cloudConnectState() {
			const protocol = this.protocol ? this.protocol : "0";
			const index = this.supportedProtocols.indexOf(protocol);
			const upToDateProtocol = this.supportedProtocols[this.supportedProtocols.length - 1];

			if(protocol === 'unknown') {
				return 'UNKNOWN'
			} else if (index < 0) {
				return "ERROR"
			} else if (upToDateProtocol === protocol) {
				return "OK"
			} else {
				return "OLD";
			}
		},
	},
}
</script>
<style lang="scss" scoped>


.flexbox-row {
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	align-content: center;
	max-width: 650px;
	width: 100%;
}
.cloud-connect-icon {
	box-sizing: border-box;
	flex-grow: 1;
	align-self: stretch;
	width: 50px;
	text-align: center;
	background-color: $white;
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
}
.cloud-connect-textarea {
	box-sizing: border-box;
	flex-grow: 1;
	width: 100%;
	font-size: small;
	padding: 8px;
}

.error {
	border: 1px solid $errorColorDark;
}
.ok {
	border: 1px solid $primaryColor;
}
.old, .unknown {
	border: 1px solid $warningColor;
	filter: brightness(90%);
}
.icon-error-area {
	background-color: $errorColorLight;
}

.icon-ok-area {
	background-color: $primaryColorLight;
}

.icon-old-area, .icon-unknown-area {
	background-color: $warningColor;
}

.icon-error {
	color: $white;
}
.icon-ok {
	color: $white;
}
.icon-old, .icon-unknown {
	color: $secondaryColorDark;
}

</style>
