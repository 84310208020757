import STORE_CONSTANTS from "./constants";

export default {
	namespaced: true,
	state: {
		editState: false,
		saveInProgress: false,
		tenantDataChanged: false,
	},

	getters: {
		[STORE_CONSTANTS.editTenant.getters.queryEditState]: state => state.editState,
		[STORE_CONSTANTS.editTenant.getters.querySaveInProgress]: state => state.saveInProgress,
		[STORE_CONSTANTS.editTenant.getters.queryTenantDataChanged]: state => state.tenantDataChanged,
	},

	mutations: {
		[STORE_CONSTANTS.editTenant.getters.queryEditState](state) {
			state.editState = !state.editState;
		},
		[STORE_CONSTANTS.editTenant.getters.querySaveInProgress](state) {
			state.saveInProgress = !state.saveInProgress;
		},
		[STORE_CONSTANTS.editTenant.getters.queryTenantDataChanged](state, payload) {
			state.tenantDataChanged = payload;
		},
	},

	actions: {
		[STORE_CONSTANTS.editTenant.getters.queryEditState](context) {
			context.commit(STORE_CONSTANTS.editTenant.getters.queryEditState);
		},
		[STORE_CONSTANTS.editTenant.getters.querySaveInProgress](context) {
			context.commit(STORE_CONSTANTS.editTenant.getters.querySaveInProgress);
		},
		[STORE_CONSTANTS.editTenant.getters.queryTenantDataChanged](context, payload) {
			context.commit(STORE_CONSTANTS.editTenant.getters.queryTenantDataChanged, payload);
		}
	},
};
